import EmailIcon from "@mui/icons-material/Email"
import LockIcon from "@mui/icons-material/Lock"
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField, Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material"
import {makeStyles, useTheme} from "@mui/styles"
import {signInWithRedirect} from "aws-amplify/auth"
import {LINKS} from "constants/links"
import {trim} from "lodash"
import React, {useCallback, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useNavigate} from "react-router-dom"
import {deferredConfirmation, login, setSignUp} from "store/slices/user"
import Google from "assets/icons/Google"
import MasteryCoding from "assets/pictures/masteryCoding.jpeg"
import Clever from "assets/pictures/clever.png"

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: "100%",
    width: "100%",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    overflowY: "auto",
    minHeight: "calc(100vh - 60px)"
  },
  signInContainer: {
    padding: theme.spacing(5),
    [theme.breakpoints.up("xs")]: {
      minWidth: "80%",
      maxWidth: "90%"
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "60%",
      maxWidth: "70%"
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "40%",
      maxWidth: "50%"
    }
  },
  DividerContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  DividerBar: {
    height: "2px",
    margin: "auto",
    width: "50%",
    backgroundColor: theme.palette.kpYellowColors.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  submitBtn: {
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
    margin: theme.spacing(1.5, 0, 1.5)
  },
  errMessage: {
    width: "100%"
  },
  formFootTxt: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    textAlign: "end",
    cursor: "pointer"
  },
  textInactive: {
    cursor: "default",
    color: "grey"
  },
  btn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  links: {
    color: theme.palette.secondary.main
  },
  rememberMe: {
    "&.MuiFormControlLabel-root": {
      color: theme.palette.primary.main
    }
  }
}))

export default function SignIn() {
  const {
    root,
    signInContainer,
    form,
    submitBtn,
    errMessage,
    formFootTxt,
    textInactive,
    links,
    btn,
    rememberMe
  } = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme<any>()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const {loading, errLogin} = useSelector((state: any) => state.user)
  const [values, setValues] = useState({name: "", password: ""})

  const handleChange = useCallback(
    (e) => {
      setValues({
        ...values,
        [e.target.name]: e.target.value
      })
    },
    [values]
  )

  const handleSubmit = useCallback(
    async (values) => {
      if (values) {
        const password = trim(values.password)
        setValues({
          ...values,
          password
        })
        dispatch(login(values.name, password))
      }
    },
    [dispatch]
  )

  const handleSignUp = useCallback(() => {
    navigate(LINKS.signUp)
    dispatch(setSignUp(true))
  }, [dispatch])

  const handleConfirm = useCallback(
    (name) => {
      if (name) dispatch(deferredConfirmation(name, LINKS.signUp))
    },
    [dispatch]
  )

  const handleForgotPassword = useCallback(async () => {
    navigate(LINKS.forgotPassword)
  }, [])

  return (
    <Grid className={root} container>
      {!isMobile && (
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
          xs={4}
          md={4}
          style={{
            background: "linear-gradient(rgb(118 215 255), rgb(0, 41, 85))"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: 0.5
            }}
            mb="60px"
          >
            <Typography variant="h4" align="center" color="white.main">
              Examination Center
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              color="white.main"
              fontWeight={400}
            >
              Login to take your exam
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid
        item
        xs={isMobile ? 12 : 8}
        md={8}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box className={signInContainer}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h4" color="primary" sx={{mb: 1}}>
                Log in
              </Typography>
              <Link href="#" variant="subtitle2" className={formFootTxt}>
                <span className={textInactive}>
                  Don't have an account?<>&nbsp;</>
                </span>
                <span className={links} onClick={handleSignUp}>
                  Sign Up
                </span>
              </Link>
            </Box>
            <Box
              sx={{
                height: 50,
                "& > img": {
                  maxHeight: "100%",
                  width: "auto"
                }
              }}
            >
              <img alt="Auth platform" src="/icons/kp_logo.png" />
            </Box>
          </Box>
          <form className={form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Username"
              name="name"
              type="text"
              autoFocus
              onChange={(e) => handleChange(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => handleChange(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                )
              }}
            />
            {errLogin && (
              <Grid container>
                <Grid item className={errMessage}>
                  <Typography variant="subtitle2" color="error">
                    {errLogin}
                  </Typography>

                  {errLogin === "User is not confirmed." ? (
                    <Button
                      fullWidth
                      size="large"
                      variant="outlined"
                      color="primary"
                      className={btn}
                      onClick={() => handleConfirm(values.name)}
                    >
                      Confirm Email
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            )}
            <Grid container>
              <Grid item>
                <FormControlLabel
                  className={rememberMe}
                  control={<Checkbox value="remember" color="primary" />}
                  label={
                    <Typography variant="subtitle2" color="textPrimary">
                      Remember me
                    </Typography>
                  }
                />
              </Grid>
            </Grid>

            <Button
              variant="contained"
              size="large"
              color="primary"
              disabled={loading || !values["name"] || !values["password"]}
              className={submitBtn}
              onClick={() => handleSubmit(values)}
              fullWidth
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item alignItems="center">
                <Typography
                  variant="subtitle2"
                  onClick={handleForgotPassword}
                  className={formFootTxt}
                >
                  <span className={links}>Forgot password?</span>
                </Typography>
              </Grid>
              {/*<Grid item xs>*/}
              {/*  <Link href="#" variant="subtitle2" className={formFootTxt}>*/}
              {/*  <span className={textInactive}>*/}
              {/*    Don't have an account?<>&nbsp;</>*/}
              {/*  </span>*/}
              {/*    <span className={links} onClick={handleSignUp}>*/}
              {/*    Sign Up*/}
              {/*  </span>*/}
              {/*  </Link>*/}
              {/*</Grid>*/}
            </Grid>
            <Box mt={1}>
              <Divider color="primary">
                <Typography color="textSecondary">or sign in with</Typography>
              </Divider>
            </Box>
            <Box
              mt={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Tooltip title="Mastery Coding">
                <IconButton
                  color="primary"
                  sx={{backgroundColor: theme.palette.kpNeutralColors.light}}
                  onClick={() =>
                    signInWithRedirect({provider: {custom: "MasteryCoding"}})
                  }
                >
                  <Box
                    sx={{
                      width: "1em",
                      height: "1em",
                      borderRadius: "100%",
                      overflow: "hidden"
                    }}
                  >
                    <img
                      src={MasteryCoding}
                      alt="mastery coding"
                      className="object-contain"
                    />
                  </Box>
                </IconButton>
              </Tooltip>
              <Tooltip title="Clever">
                <IconButton
                  color="primary"
                  sx={{backgroundColor: theme.palette.kpNeutralColors.light}}
                  onClick={() => signInWithRedirect({provider: {custom: "Clever"}})}
                >
                  <Box
                    sx={{
                      width: "1em",
                      height: "1em",
                      borderRadius: "100%",
                      overflow: "hidden"
                    }}
                  >
                    <img src={Clever} alt="clever" className="object-contain"/>
                  </Box>
                </IconButton>
              </Tooltip>
              <Tooltip title="Google">
               <IconButton
              color="primary"
              sx={{backgroundColor: theme.palette.kpNeutralColors.light}}
              onClick={() => signInWithRedirect({provider: "Google"})}
              >
                 <Google/>
               </IconButton>
              </Tooltip>
            </Box>
          </form>
        </Box>
      </Grid>
    </Grid>
  )
}
